.services{
    position: relative;
    background-image: url(../images/services_bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 100px 0;
}
.services::before{
    position: absolute;
    content: 'aa';
    width: 100%;
    height: 100%;
    background-color: #000;
    top: 0;
    left: 0;
    opacity: 0.5;
}
.services .section-title{
    position: relative;
    text-align: center;
    margin-bottom: 40px;
}
.services .section-title h2{
    color: #ffffff;
    font-size: 35px;
    font-weight: 700;
    text-transform: uppercase;
}
.section-title h2 span{
    color: #ff5e14;
}
.single-service h2{
    color: #ffffff;
    font-size: 25px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 25px;
}
.single-service h2::before{
    position: absolute;
    content: '';
    background-color: #ff5e14;
    width: 100px;
    height: 4px;
    top: 40px;
}
.single-service p{
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
}

