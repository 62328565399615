.welcome{
    position: relative;
    padding: 100px 0;
}
.welcome h4{
    color: #ff5e14;
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
}
.welcome h2{
    color: #373435;
    font-size: 30px;
    font-weight: 700;
    margin: 10px 0;
    text-transform: capitalize;
}
.welcome p{
    color: #373435;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}