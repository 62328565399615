/* Navbar Css */

.navbar{
    position: fixed;
    top: 0;
    width: 100%;
    background: #000000;
    height: 110px;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar img{
    max-width: 100%;
}
.nav-menu{
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-icon{
    display: none;
}
.nav-item{
    padding: 0 10px;
}
.nav-item:last-of-type{
    padding: 0 0px;
}
.nav-link{
    color: white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.nav-link:hover{
    color: #ff5e14;
}
.header_rightMenu{
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_rightMenu a{
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin: 0 10px;
    text-decoration: none;
}
.header_rightMenu .phoneIcon{
    color: #ff5e14;
    margin-right: 8px;
}
.header_rightMenu .mailIcon{
    color: #ff5e14;
    margin-right: 8px;
}