
.padzero{
    padding: 0;
}
.aboutInfoText{
    background-color: #ff5e14;
    height: 100%;
    padding: 80px 120px;
}
.aboutInfoText h2{
    color: #ffffff;
    font-size: 35px;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.aboutInfoText p{
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}