
/* Home Slider Css */

.d-table{
    width: 100%;
    height: 100%;
}
.d-table-cell{
    vertical-align: middle;
}
img{
    max-width: 100%;
}
.main-banner{
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.main-banner::before{
    position: absolute;
    content: "";
    background-color: #373435;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.4;
}
.item-bg-one{
    background-image: url('../images/Slideone.jpg');
}
.item-bg-two{
    background-image: url('../images/Slidetwo.jpg');
}
.item-bg-three{
    background-image: url('../images/Slidethree.jpg');
}
.item-bg-four{
    background-image: url('../images/Slidefour.jpg');
}
.main-banner-text{
    position: absolute;
    content: '';
    top: 40%;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease;
}
.main-banner-text h1{
    color: #ffffff;
    font-size: 65px;
    font-weight: 900;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.main-banner-text p{
    color: whitesmoke;
    font-weight: 500;
    max-width: 700px;
    line-height: 32px;
}

.owl-theme .owl-nav{
    margin-top: 0;
}
.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    top: 50%;
    left: -5px;
    background-color: #ff5e14 !important;
    width: 50px;
    height: 50px;
    color: #fff;
    line-height: 45px;
    text-align: center;
    font-size: 30px !important;
    border-radius: 0px !important;
    transition: all 0.3s ease;
}
.owl-theme .owl-nav [class*="owl-"]:hover{
    background-color: #0000ff;
    color: #fff;
}
.owl-theme .owl-nav .owl-next{
    left: auto;
    right: -5px;
    cursor: pointer;
}
.owl-theme .owl-nav .owl-prev{
    transform: rotate(180deg);
    cursor: pointer;
}

.owl-theme .owl-dots {
    line-height: .1;
    position: absolute;
    left: 46%;
    bottom: 80px;
    transition: all 0.3s ease;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    width: 12px !important;
    height: 12px !important;
    margin: 0 5px;
    background: #ff5e14 !important;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span {
    background: #0000ff !important;
  }
