.padzero{
    padding: 0;
}
.productInfo{
    position: relative;
    padding: 100px 0;
}
.passengerImage > img{
    max-width: 100%;
}
.passengerText{
    background-color: #ffffff;
    height: 100%;
    padding: 0px 50px;
}
.passengerText h2{
    color: #222222;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.passengerText ul{
    list-style-type: none;
    margin-bottom: 0;
}
.passengerText li{
    color: gray;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
}
.passengerTextIcon{
    color: #ff5e14;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-right: 10px;
}
.hospitalText{
    background-color: #ff5e14;
    height: 100%;
    padding: 50px 50px;
}
.hospitalText h2{
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.hospitalText p{
    color: whitesmoke;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.goodsText{
    background-color: #ffffff;
    height: 100%;
    padding: 50px 50px;
}
.goodsText h2{
    color: #222222;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.goodsText p{
    color: gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.mrlText{
    background-color: #ff5e14;
    height: 100%;
    padding: 50px 50px;
}
.mrlText h2{
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.mrlText p{
    color: whitesmoke;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.hydraulicText{
    background-color: #ffffff;
    height: 100%;
    padding: 50px 50px;
}
.hydraulicText h2{
    color: #222222;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.hydraulicText p {
    color: gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 15px;
}
.hydraulicText ul{
    list-style: none;
}
.hydraulicText ul li{
    color: gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}
.hydraulicTextIcon{
    color: #ff5e14;
    font-size: 20px !important;
    margin-right: 10px;
}
.homeText{
    background-color: #ff5e14;
    height: 100%;
    padding: 50px 50px;
}
.homeText h2{
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.homeText p{
    color: whitesmoke;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.cabinText{
    background-color: #ffffff;
    height: 100%;
    padding: 50px 50px;
}
.cabinText h2{
    color: #222222;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.cabinText p{
    color: gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20px;
}
.cabinText h4{
    color: #222222;
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.cabinText ul li{
    list-style: none;
    margin-bottom: 0;
    color: gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}
.cabinTextIcon{
    color: #ff5e14;
    font-size: 18px !important;
    margin-right: 10px;
}
.doorsText{
    background-color: #ff5e14;
    height: 100%;
    padding: 50px 50px;
}
.doorsText h2{
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.doorsText p{
    color: whitesmoke;
}





