*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Leelawadee';
}
body{
    font-family: 'Leelawadee';
    font-size: 16px;
    margin: 0;
    background: white;
}
h1,h2,h3,h4,h5,h6{
    margin-bottom: 0;
    font-size: 600;
}
p{
    color: gray;
    font-size: 16px;
    font-family: 'Leelawadee';
    font-weight: 400;
    line-height: 28px;
    margin: 0;
}
ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}
img{
    max-width: 100%;
}

