.whyus{
    position: relative;
    padding: 100px 0;
}
.whyus .section-title{
    position: relative;
    padding-bottom: 40px;
}
.whyus .section-title h2{
    color: #ff5e14;
    font-size: 35px;
    font-weight: 700;
    text-transform: uppercase;
}
.whyus .section-title h2 span{
    color: #000000;
}
.single_why .icon{
    color: #ff5e14;
    font-size: 50px;
    font-weight: 700;
    transition: all 0.4s ease;
}
.single_why h4{
    color: #000000;
    font-size: 25px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 12px 0;
}
.single_why p{
    color: #373435;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
