.footer_top{
    position: relative;
    padding: 50px 0;
    background-color: #222222;
}
.footer_top h2{
    position: relative;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 30px;
}
.footer_top p{
    color: #cccccc;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.single_footerContact{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.single_footerContact .icon{
    color: #ff5e14;
    font-size: 24px;
    margin-right: 10px;
}
.footer_bottom{
    position: relative;
    background-color: #000000;
}
.copyrightText{
    text-align: center;
    padding: 20px;
}
.copyrightText p{
    color: #cccccc;
    font-size: 14px;
    font-weight: 400;
}
.copyrightText p a{
    color: #ff5e14;
    text-decoration: none;
    font-weight: 600;
}


